.sentence-markup {
  ruby {
    display: inline-flex;
    flex-direction: column-reverse;
  }

  rt {
    display: block;
    line-height: 1.3;
    font-size: 55%;
  }

  rb {
    display: inline;
    line-height: 1.3;
  }
}
