$height: 800px;

.kanji-flow-viewer {
  display: grid;
  width: 100%;
  grid-template-rows: $height 1fr;
}

.kanji-flow {
  width: 100%;
  height: $height;
  background: linear-gradient(to top, #f7f1fc 5%, #ffffff 100%);

  .kanji-node {
    cursor: pointer;
  }

  .frame {
    fill: none;
    stroke: rgba($color: #909090, $alpha: 0.75);
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .frame-highlight,
  .highlight {
    stroke: #161616;
  }
}
